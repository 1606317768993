<header>

  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid col-4">
      <a class="navbar-brand" [routerLink]="['home']">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          class="bi bi-house-door-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"
          />
        </svg>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" id="list-nav">
            <a class="nav-link" [routerLink]="['home']">Home</a>
          </li>

          <li class="nav-item" id="list-nav">
            <a class="nav-link" [routerLink]="['sobre']">Sobre</a>
          </li>
          <li class="nav-item" id="list-nav">
            <a class="nav-link" [routerLink]="['projetos']">Projetos</a>
          </li>
          <li class="nav-item" id="list-nav">
            <a class="nav-link" [routerLink]="['contato']">Contato</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</header>
